<template>
  <div id="dashboard-page">
    <b-container
      class="page-header"
      :class="{'pt-3' : subscription.trial === 1}"
      fluid>
      <div class="header-wrapper">
        <div class="left">
          <div class="leaderboard-wrapper">
            <Leaderboard
              v-show="showBoard"
              @hide="showBoard = !showBoard"
            />
            <div class="text-center">
              <div
                class="activity-button"
                v-show="!showBoard"
                title="Show Activity Boards"
                @click="showBoard = !showBoard">
                <b-row align-v="center" no-gutters>
                  <b-col cols="4">
                    <img
                      class="img-fluid"
                      src="../../assets/images/level.png"
                      style="height: 50%;"
                    />
                  </b-col>
                  <b-col cols="8">
                    Activity<br>Boards
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <img
            class="img-fluid h3-logo"
            id="h3-logo"
            src="../../assets/images/Healthy-Hip-Hop-Logo.png"
            alt="H3, Healthy Hip Hop, Logo"
          />
        </div>
      </div>
    </b-container>
    <b-container class="content-container pt-4 pb-3 top">
      <h1 class="text-center">Featured</h1>
      <VideoCarousel display="featured-videos" />

      <h1
        class="text-center"
        v-if="$route.path !== '/music' && playlists.length > 0">
        Playlists
      </h1>
      <BannerCarousel />
      <h1 class="mb-0 text-center">Events</h1>
    </b-container>
    <div class="full-width">
      <b-container class="content-container">
        <b-row align-v="center">
          <b-col class="text-center" cols="12" lg="5">
            <img
              class="img-fluid events"
              src="../../assets/images/Events-Image.jpg"
              alt="Events, hip hop, vs, rap battle"
            />
          </b-col>
          <b-col cols="12" lg="7">
            <div class="text-center">
              <h2 class="pt-3 mb-3">JUNGLE VS ZOO<br>RAP OFF</h2>
              <h4 class="mb-3">COMING SOON</h4>
              <!-- <h4>OCTOBER 27th 6PM</h4>
              <router-link to="/">
                <img
                  class="mt-3 mb-3 sign-up-button"
                  src="../../assets/images/home/Sign-up-Button.png"
                  alt="H3 Account, Sign up, login"
                />
              </router-link> -->
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="content-container text-center pb-4 bottom">
      <h1 class="text-center pt-4">What's New</h1>
      <VideoCarousel display="new-videos" />

      <div v-for="(char, index) in charsMedia" :key="index">
        <h1 class="text-center"> {{ char.title }} </h1>
        <VideoCarousel :cards="char.videos" display="other-videos" />
      </div>
    </b-container>

    <modals-container />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  
  export default {
    name : 'DashboardPage',
    head : {
      title : {
        inner      : 'Dashboard',
        separator  : '|',
        complement : 'Healthy Hiphop',
      },
    },
    data() {
      return {
        showBoard : false,
      }
    },
    computed : {
      ...mapGetters({
        subscription : 'subscription/subscription',
        playlists    : 'playlist/playlistBanners',
        charsMedia   : 'categories/charsMedia',
      }),
    },
    components : {
      'VideoCarousel'  : () => import('./VideoCarousel'),
      'BannerCarousel' : () => import('@/components/playlist/BannerCarousel'),
      'Leaderboard'    : () => import('@/components/leaderboard/Widget'),
    },
    watch : {
      showBoard(val) {
        if (val) {
          this.$analytics.fbq.event('PageView', {
            'page'     : 'leaderboards',
            'platform' : 'website',
            'user_id'  : this.$store.getters['user/user'].user_id,
          });
        }

      },
    },
    mounted() {
      const user = this.$store.getters['user/user'];
      this.$analytics.fbq.event('PageView', {
        'page'     : 'user-home',
        'platform' : 'website',
        'user_id'  : user.user_id,
      });
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  #dashboard-page {
    background-image: $dashboard-bg;
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 100vh;
  }

  h1, h2, h3, h4 {
    color: #fff;
    font-family: 'WickedMouse-Bold';
    font-weight: bold;
    text-shadow: none;
    -webkit-text-stroke: 1px $blue-text-stroke;
  }

  .page-header {
    position: fixed;
    display: none;
    top: 0;
    z-index: 1;

    .header-wrapper {
      display: flex;
      width: 100%;
      z-index: 1;
      padding-left: 2rem;
      padding-right: 1rem;
      align-items: flex-start;
      justify-content: space-between;

      div {
        display: inline-block;
        vertical-align: middle;
      }

      .right {
        float: right;
        width: 16.5%;
      }

      .left {
        width: 30%;
      }
    }

    .activity-button {
      padding: 1rem;
      background-color: #e762ca;
      border: 2.5px solid #fff;
      border-top: none;
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      box-shadow: 0 3px 10px rgba(119, 119, 119, 0.4);
      color: #fff;
      font-family: 'WickedMouse';
      -webkit-text-stroke: 1px $primary-color;
      
      &:hover {
        cursor: pointer;
      }
    }
  }

  .h3-logo {
    margin-right: 3rem;
    padding: 1rem;
    width: 100%;
    background-color: #01FAFE;
    border: 3px solid #fff;
    border-top: none;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    box-shadow: 0 3px 10px hsla(0,0%,46.7%,.4);
  }

  .full-width {
    background-image: $events-bg;
    background-position: center bottom;
  }

  /* Extra Small devices */
  @media only screen and (max-width: $xs-max) {
    h2 {
      font-size: 1.2em !important;
    }
    .top {
      padding-top: 8rem !important;
    }
    .events {
      margin: 0 auto;
      width: 80%;
    }
    .sign-up-button {
      width: 40%;
    }
  }
  /* Small devices (portrait tablets and large phones) */
  @media only screen and (min-width: $sm-min) {
    .top {
      padding-top: 10rem !important;
    }
    .events {
      width: 70%;
    }
    .sign-up-button {
      width: 60%;
    }
  }
  /* Medium devices (landscape tablets) */
  @media only screen and (min-width: $md-min) {
    .top {
      padding-top: 13rem !important;
    }
    .events {
      width: 70%;
    }
  }
  /* Large devices (laptops/desktops) */
  @media only screen and (min-width: $lg-min) {
    .content-container {
      margin-left: calc(#{$sidebar-width} + 2em);
    }
    .top {
      padding-top: 7rem !important;
    }
    .content-container {
      padding-left: $sidebar-width;
    }
    .fullwidth-container, .full-width {
      padding-left: $sidebar-width;
    }
    .page-header {
      display: block !important;
      padding-left: 6rem;
      padding-right: 3rem;

      .header-wrapper {
        .left {
          width: 40%;
        }
      }
    }
  }
  /* Extra large devices (large laptops and desktops) */
  @media only screen and (min-width: $xl-min) {
    .top {
      padding-top: 6rem !important;
    }
  }
  @media only screen and (min-width: $special) {
    .page-header {
      padding-left: 4rem;
    }
    .top {
      padding-top: 6rem !important;
    }
  }
  @media only screen and (min-width: $xl2-min) {
    h1 {
      font-size: 2.8em !important;
    }
    .page-header {
      padding-left: 6.3rem;
    }
    .top {
      padding-top: 7rem !important;
    }
    .content-container {
      padding-left: $sidebar-width;
    }
    .fullwidth-container, .full-width {
      padding-left: $sidebar-width;
    }
    .sign-up-button {
      width: 40%;
    }
    .events {
      width: 90%;
    }
    .h3-logo {
      right: 0;
      text-align: right;
    }
  }
  /* iMac Res */
  @media only screen and (min-width: $xl5-min) {
    .top {
      padding-top: 9rem !important;
    }
    .content-container {
      padding-left: calc(#{$sidebar-width} + 2em) !important;
    }
    .page-header {
      padding-left: 9rem;
      .header-wrapper {
        .left {
          width: 40%;
        }
      }
    }
  }
</style>